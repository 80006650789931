<template>
    <div>
        <section class="l-welcome l-guide">
            <h1 class="l-welcome-ttl"><span class="l-midashi">利用ガイド</span>サービス利用までの流れ</h1>
            <ol class="l-step">

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">会員登録</h2>
                            <p>サービスの利用には会員登録およびログインが必要です。</p>
                            <p>ニックネーム、メールアドレス、パスワード、電話番号、生年月日、性別、郵便番号、姓名（ふりがな）を入力し、会員登録を行ってください。 </p>
                        </div>
                        <div class="lp-section--img object-center">
                            <button data-v-9bbed40e="" class="btn-register btn-radius" @click="_register">会員登録<span
                                    class="sub">（無料）</span></button>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">プロフィールの設定</h2>
                            <p>会員登録が完了したら、マイページにてプロフィールの編集を行いましょう。</p>
                            <h3 class="l-ttl">記入例（生徒）</h3>
                            <ul class="disc">
                                <li>ご自身が経験した受験</li>
                                <li>相談したいお子様のご学齢</li>
                                <li>志望校</li>
                                <li>気になっていることなど</li>
                            </ul>
                            <h3 class="l-ttl">記入例（講師）</h3>
                            <ul class="disc">
                                <li>ご自身の指導経験</li>
                                <li>過去の実績</li>
                                <li>得意な相談内容</li>
                                <li>メディアへの出演経歴等</li>
                            </ul>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/img01.png" alt="自己紹介編集画面"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">レッスンを探そう</h2>
                            <p>プロフィールの設定が完了したら、受講したいレッスンを探してみましょう。ここでは「カテゴリ」で検索する方法を紹介します。</p>
                            <h3 class="l-ttl">記入例（生徒）</h3>
                            <p>カテゴリでは、「未就学児」「小学生」「中学生」といった学齢による親カテゴリと「大学受験」「就職」「高校受験」といった各子カテゴリでの検索が可能です。</p>
                            <p>また、それぞれの検索結果は「新着順」「価格順」「レビュー順」などに入れ替えることも可能なので、自分の要望に合わせたレッスンを探してみましょう。</p>
                            <p>「気になるレッスンがあるけど、もう少し詳細を確認したい」場合は、レッスンページから担当講師に問い合わせることも可能です。</p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/img02.png" alt="すべてのカテゴリ画面"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">レッスンを購入しよう</h2>
                            <p>各レッスンには、「基本料金」と「オプション」が設定されています。自身が受講するレッスンの金額に問題がなければ、「予約画面に進む」をクリックしてレッスンの予約内容確認画面に進みましょう。
                            </p>
                            <p>予約内容確認画面では具体的な相談内容を記入できます。担当講師の方に聞きたい内容やレッスン受講にあたって必要な記入事項がある場合は、こちらでご入力ください。</p>
                            <p>レッスンの購入時はクレジットカードまたはデビットカードを使用します。必要事項をご記入の上、注文の確認に進んで下さい。<br>
                                <span class="at">※決済システムにはStripeを使用しています</span>
                            </p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/img03.png" alt="担当講師画面"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">日程を決めよう</h2>
                            <p>レッスンの購入が完了すると、レッスンリスト→トークルームで自分が受講予定のレッスンを確認できます。講師の方への質問や受講日程の調整などはこちらのトークルームより行いましょう。</p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/img04.png" alt="日程"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">レッスン実施</h2>
                            <p>日程が確定したら、相談員とのレッスンを受ける準備が整いました！<br>
                                レッスンの開始時間に合わせて、必要な準備や資料の準備を行ってください。</p>
                            <p>レッスンの形式に応じて、オンライン上でのビデオ通話や対面での面談など、指示に従ってレッスンを受けてください。</p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/img05.png" alt="レッスン風景"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">受講報告</h2>
                            <p>レッスン終了後は「評価完了」テキストより評価ページへの遷移が可能となります。無事受講が完了したレッスンにつきましては、レッスンの評価および受講報告をお願いいたします。</p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/img06.png" alt="レビュー"></figure>
                        </div>
                    </div>
                </li>

            </ol>
        </section>

        <section class="l-welcome l-guide">
            <h2 class="ttl">レッスンを受講する際の注意事項</h2>
            <div class="lp-section--inner notes">
                <ul>
                    <li>
                        <h3 class="subttl">講師のプロフィールを確認する</h3>
                        レッスンを受講する前に、<span
                            class="u-white">講師のプロフィールを注意深く確認</span>しましょう。講師の専門分野や経験、教育スタイルなどをチェックし、自身や子供のニーズに合った講師を選ぶことが重要です。
                    </li>
                    <li>
                        <h3 class="subttl">レッスンの詳細を確認する</h3>
                        レッスンの詳細ページを十分に読み、<span
                            class="u-white">内容や目標、カリキュラム、料金などを確認</span>しましょう。レッスンが自身や子供の目的に合致し、期待する学習成果を得られるかどうかを判断するために重要です。
                    </li>
                    <li>
                        <h3 class="subttl">事前に質問や要望を伝える</h3>
                        レッスンを受講する前に、講師に対して<span
                            class="u-white">質問や特別な要望がある場合は、事前に伝えて</span>おきましょう。レッスンの内容やスケジュール、教材などに関する疑問や要望を共有し、円滑なコミュニケーションを図ることが大切です。
                    </li>
                    <li>
                        <h3 class="subttl">キャンセルポリシーを理解する</h3>
                        レッスンのキャンセルに関するポリシーや期限を確認し、理解しておきましょう。万が一予定が変更になる場合やキャンセルが必要な場合に備えて、<span
                            class="u-white">事前にルールを把握しておく</span>ことが重要です。
                    </li>
                    <li>
                        <h3 class="subttl">フィードバックや評価について</h3>
                        レッスンを受講した後は、<span
                            class="u-white">講師へのフィードバックや評価をお願いいたします</span>。レッスンの質や内容についての意見を共有することで、講師の改善や他の保護者への参考になります。
                    </li>
                </ul>
            </div>
        </section>
        <MetaTags />
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        isMember() {
            return this.user.info;
        },
        ...mapGetters({
            user: "member/getUser"
        }),
    },
    methods: {
        _register() {
            if(this.isMember) {
                window.$("#mms-edit-profile").click();
            }else {
                window.$("#mms-sign-up").click();
            }
        },
    },
}
</script>

<style></style>